<template>
    <sc-modal @close="$emit('close')" :title="`Schedule ${item.playlist_item_type}: ${filename(item)}`">

        <div class="dates">
            <sc-form-field class="date" :form="null" field="duration" name="Duration">
                <template-time :time.sync="duration"></template-time>
            </sc-form-field>
            <div style="margin: 0 2em;"></div>
            <sc-form-field name="valid range" description="(leave start date blank to disable valid range)">
                <div class="dates">
                    <sc-form-field class="date" :form="null" field="startDate" name="Start date">
                        <datepicker v-model="schedule.startDate" format="DD-MM-YYYY"></datepicker>
                    </sc-form-field>
                    <div style="margin: 0 .5em;"></div>
                    <sc-form-field class="date" :form="null" field="endDate" name="End date">
                        <datepicker v-model="schedule.endDate" format="DD-MM-YYYY"></datepicker>
                    </sc-form-field>
                </div>
            </sc-form-field>
        </div>

        <div class="times">

            <sc-form-field name="Time Schedule">

                <div class="time-add-button">
                    <sc-button @click="addTime">
                        <awesome-icon icon="plus-square" class="icon"/>
                        Add Time Schedule
                    </sc-button>
                </div>

                <div class="time-schedule" v-if="schedule.times.length === 0">
                    <span style="width:100%;text-align: center;">
                        <awesome-icon icon="info" class="icon"/>
                        No time schedules added yet
                    </span>
                </div>

                <div class="time-schedule" v-else v-for="(t,i) in schedule.times">
                    <div class="dates">
                        <sc-form-field class="date" :form="null" name="start time">
                            <template-time :time.sync="t.start_time" :without-seconds="true"></template-time>
                        </sc-form-field>

                        <div style="margin: 0 1em;"></div>

                        <sc-form-field class="date" :form="null" name="end time">
                            <template-time :time.sync="t.end_time" :without-seconds="true"></template-time>
                        </sc-form-field>
                    </div>

                    <div style="margin: 0 2em;"></div>

                    <sc-form-field class="date" :form="null" name="Days of the week">
                        <nr-radio :value.sync="t.days" :multi="true" :options="[
                              {label:'Sunday', value:'SUNDAY'},
                              {label:'Monday', value:'MONDAY'},
                              {label:'Tuesday', value:'TUESDAY'},
                              {label:'Wednesday', value:'WEDNESDAY'},
                              {label:'Thursday', value:'THURSDAY'},
                              {label:'Friday', value:'FRIDAY'},
                              {label:'Saturday', value:'SATURDAY'},
                              ]">
                        </nr-radio>
                    </sc-form-field>

                    <div class="item-button">

                        <sc-button color="dark" @click="removeTime(i)">
                            <awesome-icon icon="trash"/>
                        </sc-button>
                    </div>
                </div>
            </sc-form-field>

            <div style="display: flex; justify-content: center;">
                <sc-button @click="submit">Submit Schedule</sc-button>
            </div>
        </div>
    </sc-modal>
</template>

<script>
import datepicker from "vue2-datepicker";
import TemplateTime from "@/views/app/templates/components/template-time";
import 'vue2-datepicker/index.css';
import moment from "moment-timezone";

const WEEKDAYS = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

export default {
  name: "item-schedule",
  components: {datepicker, TemplateTime},
  props: {
    item: {required: true, type: Object}
  },
  data() {
    return {
      duration: this.secondsToTime(this.item.duration),
      schedule: {
        startDate: undefined,
        endDate: undefined,
        times: [],
      },
    }
  },
  computed: {
    hasSchedule() {
      return ![undefined, null].includes(this.item.schedule)
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.hasSchedule) {
        // Set given schedule information
        if (this.item.schedule.start_date) this.schedule.startDate = new Date(this.item.schedule.start_date);
        if (this.item.schedule.end_date) this.schedule.endDate = new Date(this.item.schedule.end_date);

        if (this.item.schedule.times.length > 0) {
          for (let i =0; i < this.item.schedule.times.length; i++) {
            let t = this.item.schedule.times[i];
            this.addTime();
            this.schedule.times[i].start_time = t.start_time;
            this.schedule.times[i].end_time = t.end_time;
            this.schedule.times[i].days = t.days;
          }
        }
      }
    })
  },

  methods: {

    dateToYMD(date) {
      if (!date) return
      return moment(date).format("YYYY-MM-DD");
    },

    secondsToTime(seconds) {
      let h = 0, m = 0, s = 0;
      let minutes;

      s = seconds % 60;
      if (seconds >= 60) {
        minutes = (seconds - s) / 60;
        m = minutes % 60;

        if (minutes >= 60) {
          h = (minutes - m) / 60
        }
      }

      return `${this.intToDecimalString(h)}:${this.intToDecimalString(m)}:${this.intToDecimalString(s)}`
    },

    intToDecimalString(int) {
      return int < 10 ? `0${int}` : int.toString();
    },

    timeToSeconds(time) {
      // Split time and reverse to sec-min-hour
      let smh = time.split(':').reverse();
      let s = 0;
      for (let i = 0; i < smh.length; i++) {
        s += parseInt(smh[i]) * Math.pow(60, i);
      }

      return s
    },

    filename(item) {
      if (item.thumb !== null) {
        let split = item.thumb.split('/');
        return split[split.length - 1];
      }
    },

    addTime() {
      this.schedule.times.push({
        start_time: '00:00',
        end_time: '23:59',
        days: WEEKDAYS
      })
    },

    removeTime(index) {
      this.schedule.times.splice(index, 1);
    },

    submit() {
      this.item.duration = this.timeToSeconds(this.duration);
      this.item.schedule = {
        start_date: this.dateToYMD(this.schedule.startDate),
        end_date: this.dateToYMD(this.schedule.endDate),
        times: this.schedule.times.map((t) => {
          return {
            start_time: t.start_time,
            end_time: t.end_time,
            days: WEEKDAYS.map(d => t.days.includes(d) ? d : false)
          }
        })
      }

      this.$notify.success("Schedule applied, please save playlist.");
      this.$emit('close');
    }
  }
}

</script>

<style scoped lang="scss">

.dates {
    display: flex;
    justify-content: space-between;
}

.times {
    position: relative;

    .time-add-button {
        position: absolute;
        right: 0;
        transform: translateY(-100%);
        margin-bottom: 1em;
    }

    .time-schedule {
        display: flex;
        border: 1px solid #e2e2e2;
        padding: .5em 1em;
        position: relative;
        text-align: center;

        .item-button {
            display: flex;
            height: inherit;
            justify-content: center;
            align-items: center;
        }

        .sc-form-row {
            margin-bottom: 0;
        }
    }
}

</style>