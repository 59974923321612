<template>
    <sc-modal @close="$emit('close')" v-if="table" :title="`Choose ${type} items`">

        <sc-search class="search" :table="table" field="q" v-if="searchable"></sc-search>

        <div class="images">
            <div class="image" v-for="(item,i) in table.rows()" :key="item.id" @click="toggle(item)">
                <div class="item-preview">

                    <img :src="imageURL(item.attributes ? item.attributes.thumb : item.thumb)"
                         v-if="type === 'media' && item.attributes.type === 'image' || type !== 'media'"/>

                    <div class="icon-type" v-else-if="item.attributes.type === 'html'">
                        <awesome-icon :icon="['fab','html5']" class="fa-4x"/>
                    </div>

                    <video v-else-if="item.attributes.type === 'video'" controls>
                        <source :src="imageURL(item.attributes.src)" :type="videoType(item)"/>
                    </video>
                </div>
                <div class="name">{{ item.name || item.attributes.name }}</div>

                <awesome-icon v-if="isChecked(item,i)" icon="check" class="selected"></awesome-icon>
            </div>
        </div>

        <div class="btns">

            <sc-button @click="$emit('updateSelected', changes)" v-if="hasChanged" style="margin-right: 1em;">
                Apply changes
            </sc-button>
            <sc-button @click="$emit('close')">
                Cancel
            </sc-button>
        </div>

        <template #modal-footer>
            <sc-paginate :table="table"></sc-paginate>
        </template>

    </sc-modal>
</template>

<script>

import ScSearch from "@/components/sc-search";

export default {
  name: "item-select",
  components: {ScSearch},
  props: {
    type: {required: true},
    selected: {},
  },

  data() {
    return {
      resource: undefined,
      args: undefined,
      table: undefined,
      changes: {
        add: [],
        remove: []
      }
    }
  },

  computed: {
    searchable() {
      return this.table && ['media', 'playlist'].includes(this.type);
    },

    hasChanged() {
      return this.changes.add.length > 0 || this.changes.remove.length > 0;
    }
  },

  mounted() {
    // Dev check
    if (!['media', 'playlist', 'feed', 'message', 'tvfeed'].includes(this.type)) {
      this.$emit('close');
      throw new Error("Type not accepted");
    }

    let resource;
    let args;
    switch (this.type) {
      case 'media':
        resource = 'media';
        args = '&for=playlist';
        break;
      case 'playlist':
        resource = 'playlists/available-sub-playlists';
        break;
      case 'feed':
        resource = 'feeds';
        break;
      case 'message':
        resource = 'messages';
        args = '&category=ois'
        break;
      case 'tvfeed':
        resource = 'system-templates';
        args = '&category=tvfeed';
        break;
    }

    this.resource = resource;
    this.args = args;

    this.fetchItems();
  },

  methods: {
    fetchItems() {
      this.table = this.$stable.createTable({
        url: this.resource,
        fetchArguments: () => {
          if (this.args) {
            return this.args
          }
        },
        columns: {}
      })
      this.table.fetch()
    },

    imageURL(path) {
      return process.env.VUE_APP_AWS_URL + path;
    },

    videoType(item) {
      let name = item.attributes.name.split('.');
      let ext = name[name.length - 1];
      return `video/${ext}`
    },

    isSelected(item, i) {
      return this.selected.find(s => {
        if (this.type === 'feed') {
          return s.playlist_item_id === item.id
            && s.playlist_item_type === this.type
           && s.name.toLowerCase() === item.name.toLowerCase()
        } else {
          return s.scala_id === item.scala_id
            && s.playlist_item_type === this.type
            && s.playlist_item_id === item.id
        }
      })
    },

    isChecked(item, i) {
      let selected = this.isSelected(item,i);

      if (!selected) {
        return this.changes.add.findIndex(c => c.id === item.id) !== -1
      } else {
        return this.changes.remove.findIndex(c => c.id === item.id) === -1
      }

    },

    change(item, stack) {
      // Check if it's in stack,
      // if true remove from stack
      // if false add to stack

      let index = this.changes[stack].findIndex((i) => i.id === item.id);
      if (index !== -1) {
        this.changes[stack].splice(index,1);
      } else {
        this.changes[stack].push(item);
      }
    },

    toggle(item) {
      if (this.isSelected(item)) {
        this.change(item, "remove");
      } else {
        this.change(item, "add");
      }
    }
  }
}

</script>

<style scoped lang="scss">

.images {
    display: grid;
    grid-template-columns: repeat(5, 10em);
    grid-gap: 2em;

    .image {
        width: 10em;
        height: 10em;
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        overflow: hidden;


        .name {
            padding: 0 .5em;
            word-break: break-all;
            font-size: .8em;
            text-overflow: ellipsis;
            line-height: 1.25em;
            text-align: justify-all;
        }

        &:hover {
            background: #f6f6f6;
        }

        .selected {
            position: absolute;
            width: 5em;
            height: 5em;
            color: #adff2f;
            opacity: .7;
            top: 1em;
            left: 50%;
            transform: translateX(-50%);
        }

        .item-preview {
            height: 6em;
            margin-bottom: .5em;

            img, .icon-type, video {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }
    }
}

.search {
    border: 1px solid #aaa;
    margin-bottom: 1em;
}

.btns {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    .clear {
        margin-left: 0.5em;
    }
}

</style>